'use client';

import { Stack, Text } from '@chakra-ui/react';
import { GridEdit } from 'iconsax-react';
import { type FC } from 'react';

import { useSession } from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
import { SidebarLink } from './SidebarItem';

const PublisherText = ({
  isDisabled,
  narrowMode,
}: {
  isDisabled: boolean;
  narrowMode: boolean;
}) => {
  const t = useTranslations();
  return (
    <Text
      textTransform="uppercase"
      fontWeight="600"
      opacity={isDisabled ? '0.3' : 'none'}
      userSelect="none"
    >
      {!narrowMode ? t('seller') : t('s')}
    </Text>
  );
};

export const PublisherSidebar: FC<{
  narrowMode: boolean;
  relevantPath: string;
  activeColor: string;
}> = ({ narrowMode, relevantPath, activeColor }) => {
  const {
    account: { type: accountType },
  } = useSession();

  const t = useTranslations();

  const isDisabled = accountType === 'user';

  return (
    <Stack gap="3" cursor={isDisabled ? 'not-allowed' : 'unset'}>
      <PublisherText isDisabled={isDisabled} narrowMode={narrowMode} />

      <Stack>
        <SidebarLink
          narrowMode={narrowMode}
          isDisabled={isDisabled}
          href="/publisher/drafts"
          isActive={relevantPath === 'publisher'}
          activeColor={activeColor}
          icon={GridEdit}
        >
          <Text as="span"> {t('monetize-intents')}</Text>
        </SidebarLink>
        {/* <AIPreview narrowMode={narrowMode} /> */}
      </Stack>
    </Stack>
  );
};
