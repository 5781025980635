import {
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';
import {
  Book,
  GitHub,
  HelpCircle,
  Linkedin,
  MoreHorizontal,
  Send,
  Shield,
  Twitter,
  Users,
} from 'react-feather';

export const Socials: FC = () => {
  const t = useTranslations();
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<Icon as={MoreHorizontal} boxSize={4} />}
        variant="ghost"
        aria-label="Options"
      >
        <Icon as={MoreHorizontal} boxSize="18px" />
      </MenuButton>
      <MenuList zIndex={4} w="min-content">
        <Link
          style={{ textDecoration: 'none' }}
          href="https://www.intentable.io/"
          isExternal
        >
          <MenuItem icon={<Icon boxSize={4} as={HelpCircle} />}>
            {t('about')}
          </MenuItem>
        </Link>

        <Link
          style={{ textDecoration: 'none' }}
          href="https://docs.intentable.io/"
          isExternal
        >
          <MenuItem icon={<Icon boxSize={4} as={Book} />}>{t('docs')}</MenuItem>
        </Link>

        <Link
          style={{ textDecoration: 'none' }}
          href="https://github.com/kiroboio/ki-eth-contracts"
          isExternal
        >
          <MenuItem icon={<Icon boxSize={4} as={GitHub} />}>
            {t('github')}
          </MenuItem>
        </Link>

        <Link
          style={{ textDecoration: 'none' }}
          href="https://discord.com/invite/NrQTjjwwvq"
          isExternal
        >
          <MenuItem icon={<Icon boxSize={4} as={Users} />}>
            {t('discord')}
          </MenuItem>
        </Link>

        <Link
          style={{ textDecoration: 'none' }}
          href="https://t.me/intentable"
          isExternal
        >
          <MenuItem icon={<Icon boxSize={4} as={Send} />}>
            {t('telegram')}
          </MenuItem>
        </Link>

        <Link
          style={{ textDecoration: 'none' }}
          href="https://twitter.com/Intentable_io"
          isExternal
        >
          <MenuItem icon={<Icon boxSize={4} as={Twitter} />}>
            {t('twitter')}
          </MenuItem>
        </Link>

        <Link
          style={{ textDecoration: 'none' }}
          href="https://www.linkedin.com/company/intentableio"
          isExternal
        >
          <MenuItem icon={<Icon boxSize={4} as={Linkedin} />}>
            {t('linkedin')}
          </MenuItem>
        </Link>

        <Link
          style={{ textDecoration: 'none' }}
          href="/legal/FCT-Terms.pdf"
          isExternal
        >
          <MenuItem icon={<Icon boxSize={4} as={Shield} />}>
            {t('terms')}
          </MenuItem>
        </Link>

        <Link
          style={{ textDecoration: 'none' }}
          href="/legal/FCT-Privacy.pdf"
          isExternal
        >
          <MenuItem icon={<Icon boxSize={4} as={Shield} />}>
            {t('privacy')}
          </MenuItem>
        </Link>

        <Text
          fontSize="xs"
          textAlign="center"
          mt="2"
          color="blackAlpha.600"
          _dark={{ color: 'whiteAlpha.600' }}
        >
          {t('version-0')}
        </Text>
      </MenuList>
    </Menu>
  );
};
