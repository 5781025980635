'use client';

import { Button, Link } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';
import { TfiWrite } from 'react-icons/tfi';

import { isMarketplaceOff } from '~/lib/helpers/flags';

interface IIntentRequestButton {
  narrowMode: boolean;
}

export const IntentRequestButton: FC<IIntentRequestButton> = ({
  narrowMode,
}) => {
  const t = useTranslations();

  if (isMarketplaceOff) return null;

  return (
    <Button
      as={Link}
      isExternal
      href="https://intentable.io/typeform-intent-request/"
      leftIcon={<TfiWrite />}
      iconSpacing={narrowMode ? '0' : '3'}
      textTransform="capitalize"
      fontWeight="450"
      rounded="xl"
      colorScheme="blue"
      variant="outline"
      size="md"
      w="fit-content"
      h="30px"
      style={{ textDecoration: 'none' }}
    >
      {!narrowMode && t('intent-request')}
    </Button>
  );
};
