'use client';

import { Button, Link } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';
import { TfiWrite } from 'react-icons/tfi';

import { isBuilderDisabled } from '~/lib/helpers/flags';

interface IBusinessInquiryButton {
  narrowMode: boolean;
}

export const BusinessInquiryButton: FC<IBusinessInquiryButton> = ({
  narrowMode,
}) => {
  const t = useTranslations();

  if (isBuilderDisabled) return null;

  return (
    <Button
      as={Link}
      isExternal
      href="https://www.intentable.io/business-inquiry-form/"
      leftIcon={<TfiWrite />}
      iconSpacing={narrowMode ? '0' : '3'}
      textTransform="capitalize"
      fontWeight="450"
      rounded="xl"
      colorScheme="blue"
      variant="outline"
      size="md"
      w="fit-content"
      h="30px"
      style={{ textDecoration: 'none' }}
    >
      {!narrowMode && t('business-inquiry')}
    </Button>
  );
};
