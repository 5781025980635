'use client';

import { Stack, Text } from '@chakra-ui/react';
import { ArrowSquare, BackwardItem } from 'iconsax-react';
import { type FC } from 'react';

import {
  BigIntOr0,
  useActiveFlowList,
  useFlowPower,
  useSession,
} from '@kiroboio/fct-sdk';
import { useTranslations } from 'next-intl';
// import { GuardWithMessage } from '~/lib/components/general/GuardWithMessage';
// import {
//   becomeABuilderId,
//   useBecomeABuilderModalActions,
// } from '~/lib/hooks/useModals/useComplexModals';
import { SidebarLink } from './SidebarItem';

const BuilderText = ({
  isDisabled,
  narrowMode,
}: {
  isDisabled: boolean;
  narrowMode: boolean;
}) => {
  const t = useTranslations();
  return (
    <Text
      textTransform="uppercase"
      fontWeight="600"
      opacity={isDisabled ? '0.3' : 'none'}
      userSelect="none"
    >
      {!narrowMode ? t('builder') : t('b')}
    </Text>
  );
};

export const BuilderSidebar: FC<{
  narrowMode: boolean;
  relevantPath: string;
  activeColor: string;
}> = ({ narrowMode, relevantPath, activeColor }) => {
  const {
    account: { type: accountType },
  } = useSession();
  // const { open: onOpenBecomeABuilder } =
  //   useBecomeABuilderModalActions(becomeABuilderId);

  // const handleOpenBecomeABuilder = useCallback(() => {
  //   onOpenBecomeABuilder({});
  // }, [onOpenBecomeABuilder]);

  const {
    raw: {
      missing: { native: powerMissingWei },
    },
  } = useFlowPower();

  const activeFlows = useActiveFlowList();

  const hasActiveFlows = activeFlows.list && Boolean(activeFlows.list.length);

  const showNeededTokenAlert = BigIntOr0(powerMissingWei) > BigIntOr0(0);
  const isMissingPower = showNeededTokenAlert && hasActiveFlows;

  const t = useTranslations();

  const isDisabled = accountType === 'user';

  return (
    <Stack gap="3" cursor={isDisabled ? 'not-allowed' : 'unset'}>
      {/* {isDisabled ? (
        <GuardWithMessage
          unsupportedNetworkJsx={
            <BuilderText isDisabled={isDisabled} narrowMode={narrowMode} />
          }
          serviceStateMessageJsx={
            <BuilderText isDisabled={isDisabled} narrowMode={narrowMode} />
          }
          systemLoadingJsx={
            <BuilderText isDisabled={isDisabled} narrowMode={narrowMode} />
          }
        >
          <Button
            onClick={handleOpenBecomeABuilder}
            textTransform="capitalize"
            fontWeight="450"
            rounded="xl"
            colorScheme="blue"
            variant="outline"
            size="md"
            w="fit-content"
            h="30px"
          >
            {!narrowMode ? t('become-a-builder') : t('be')}
          </Button>
        </GuardWithMessage>
      ) : (
        <BuilderText isDisabled={isDisabled} narrowMode={narrowMode} />
      )} */}
      <BuilderText isDisabled={isDisabled} narrowMode={narrowMode} />

      <Stack>
        <SidebarLink
          narrowMode={narrowMode}
          isDisabled={isDisabled}
          href="/builder"
          isActive={relevantPath === 'builder'}
          activeColor={activeColor}
          icon={BackwardItem}
        >
          <Text as="span">{t('intent-builder')}</Text>
        </SidebarLink>
        <SidebarLink
          narrowMode={narrowMode}
          isDisabled={isDisabled}
          href="/flows/active"
          isActive={relevantPath === 'flows'}
          activeColor={activeColor}
          icon={ArrowSquare}
          isMissingPowerSign={isMissingPower}
        >
          <Text as="span"> {t('intents-built-by-me')}</Text>
        </SidebarLink>
      </Stack>
    </Stack>
  );
};
