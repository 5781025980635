'use client';

/* eslint-disable react/jsx-no-useless-fragment */

import type { BoxProps } from '@chakra-ui/react';
import { Box, HStack, Stack } from '@chakra-ui/react';
import { usePathname } from 'next/navigation';
import type { ReactNode } from 'react';
import { useDeviceSelectors } from 'react-device-detect';

import { Sidebar } from '~/app/components/Sidebar/Sidebar';
import { Topbar } from '~/app/components/Topbar';

interface LayoutProps extends BoxProps {
  children: ReactNode;
  hideOverflow?: boolean;
}

const Layout = ({ children, hideOverflow, ...props }: LayoutProps) => {
  // const { isMobileView } = useSnapshot(viewManager);
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  const pathname = usePathname();
  const isMarketplaceApp = pathname.includes('/app/marketplace/');
  const isTradingApp = pathname.includes('/app/trading/');
  const isYieldApp = pathname.includes('/app/yields/');
  const isAppPage = isMarketplaceApp || isTradingApp || isYieldApp;

  if (isMobile) {
    return (
      <HStack h="100%" overflow="hidden">
        <Sidebar />
        <Stack px="12px" pt="0" h="full" overflow="hidden" flex="1">
          <Topbar />
          <Box
            id="main"
            as="main"
            flex="1"
            overflow={hideOverflow ? 'hidden' : 'auto'}
            px="2px"
            pt="0"
          >
            {children}
          </Box>
        </Stack>
      </HStack>
    );
  }
  return (
    <HStack h="100%" overflow="hidden">
      {!isAppPage && <Sidebar />}
      <Stack h="full" overflow="hidden" flex="1">
        <Topbar />
        <Box
          id="main"
          as="main"
          flex="1"
          overflow={hideOverflow ? 'hidden' : 'auto'}
          px={['8px', '16px', '32px']}
          pt="0"
          {...props}
        >
          {children}
        </Box>
      </Stack>
    </HStack>
  );
};

export default Layout;
