'use client';

// BEGIN: 5f3d4c7d6c5a
import type { ButtonProps } from '@chakra-ui/react';
import { Button, HStack, Icon, Text, Tooltip } from '@chakra-ui/react';
import { useNetwork as useSDKNetwork } from '@kiroboio/fct-sdk';
// import { useWeb3Modal } from '@web3modal/react';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { Brodcast } from 'iconsax-react';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';
import { useNetwork } from 'wagmi';

export const NetworkSwitcher: FC<ButtonProps> = (props) => {
  const { open } = useWeb3Modal();
  const { data: networkData } = useSDKNetwork();
  const network = useNetwork();
  const isUnsupportedNetwork = network?.chain?.unsupported;

  // const isNetworkSupported = networkData.raw.chainId > 0;

  const {
    gasPrice: {
      fastest: { maxFeePerGas: maxFeePerGasFmt },
    },
  } = networkData.fmt;
  const t = useTranslations();

  return (
    <Tooltip
      label={t(
        'unsupported-network-please-click-and-select-one-of-the-supported-networks'
      )}
      isOpen={Boolean(isUnsupportedNetwork)}
      hasArrow
    >
      <Button
        variant={isUnsupportedNetwork ? 'outline' : 'ghost'}
        colorScheme={isUnsupportedNetwork ? 'red' : undefined}
        onClick={() => open({ view: 'Networks' })}
        leftIcon={<Icon as={Brodcast} color="gray.500" />}
        {...props}
      >
        <HStack>
          <Text
            color={
              // eslint-disable-next-line no-nested-ternary
              networkData.raw.chainId !== 1
                ? isUnsupportedNetwork
                  ? 'red.400'
                  : 'orange.400'
                : 'green.400'
            }
            textTransform="capitalize"
          >
            {isUnsupportedNetwork ? t('unsupported') : networkData.fmt.name}:
          </Text>
          <Text>{maxFeePerGasFmt} gwei</Text>
        </HStack>
      </Button>
    </Tooltip>
  );
};
// END: 5f3d4c7d6c5a
