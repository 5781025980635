import { Button, HStack, Icon } from '@chakra-ui/react';
import type { Icon as SaxIcon } from 'iconsax-react';
import type { LinkProps } from 'next/link';
import Link from 'next/link';
import type React from 'react';
import type { FC } from 'react';
import { TbAlertTriangleFilled } from 'react-icons/tb';

export const SidebarLink: FC<
  LinkProps & {
    children?: React.ReactNode;
    icon: SaxIcon;
    isActive: boolean;
    activeColor: string;
    isDisabled?: boolean;
    isMissingPowerSign?: boolean;
    narrowMode: boolean;
  }
> = ({
  children,
  icon: IconCMP,
  isActive,
  activeColor,
  isDisabled,
  isMissingPowerSign,
  narrowMode,
  onClick,
  ...props
}) => {
  return (
    <Link
      onClick={onClick}
      data-cy="sidebar-item"
      href={isDisabled ? '/' : props.href}
    >
      <Button
        isDisabled={isDisabled}
        fontWeight="medium"
        gap="3"
        w={narrowMode ? 'fit-content' : '232px'}
        variant={isActive ? 'solid' : 'ghost'}
        display="flex"
        justifyContent="flex-start"
      >
        <HStack w="full" justifyContent="space-between">
          <HStack w="full">
            <Icon
              as={IconCMP}
              boxSize="6"
              color={isActive ? activeColor : 'gray.400'}
              variant={isActive ? 'Bold' : undefined}
            />
            {!narrowMode && children}
          </HStack>
          {!narrowMode && isMissingPowerSign && (
            <Icon as={TbAlertTriangleFilled} boxSize="24px" color="red.400" />
          )}
        </HStack>
      </Button>
    </Link>
  );
};
